export const environment = {
  production: true,
  useServiceWorker: false,
  apiBaseUrl: 'https://api-dev.parenthub.co.uk',
  accountBaseUrl: 'https://account-dev.parenthub.co.uk',
  testMode: true,
  fcmSenderId: '1043251013471',
  aiConnectionString:
    'InstrumentationKey=3ecfae78-6501-483b-baf9-b665100be839;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
  versionName: '2024.07.12.1'
};
